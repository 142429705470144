import React from 'react';
import Main from '../components/Main/Main';
import Employee from '../components/Employee/Employee';
import Grid from '../components/Grid/Grid';

import aleksander from '../assets/employees/aleksander.jpeg';
import thomas from '../assets/employees/thomas.jpeg';
import vilje from '../assets/employees/vilje.jpeg';
import torstein from '../assets/employees/torstein.jpeg';
import frederik from '../assets/employees/frederik.jpeg';
import hauk from '../assets/employees/hauk.jpeg';
import marte from '../assets/employees/marte.jpg';

const Contact = () => {
  return (
    <Main>
      <Grid>
        <Employee
          name={'Aleksander Hetland'}
          image={aleksander}
          title={'Daglig leder'}
          phone={41020432}
          email={'aleksander@smeh.no'}
        />
        <Employee
          name={'Vilje Mohn'}
          image={vilje}
          title={'Prosjektleder'}
          phone={91739125}
          email={'vilje@smeh.no'}
        />
        <Employee
          name={'Thomas Larsen Eltvik'}
          image={thomas}
          title={'Kreatør / Tekstforfatter'}
          phone={46430770}
          email={'thomas@smeh.no'}
        />
        <Employee
          name={'Torstein Søreide Skogedal'}
          image={torstein}
          title={'Kreatør / Art Director'}
          phone={91582909}
          email={'torstein@smeh.no'}
        />
        <Employee
          name={'Hauk Brochmann'}
          image={hauk}
          title={'Kreatør / Art Director'}
          phone={94156134}
          email={'hauk@smeh.no'}
        />
        <Employee
          name={'Frederik Feiler'}
          image={frederik}
          title={'Kreatør / Art Director'}
          phone={97154424}
          email={'frederik@smeh.no'}
        />
        <Employee
          name={'Marte Sønsterud'}
          image={marte}
          title={'Grafisk Designer'}
          phone={45421559}
          email={'marte@smeh.no'}
        />
      </Grid>
    </Main>
  );
};

export default Contact;
