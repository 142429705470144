import styles from './Employee.module.scss';
import PropTypes from 'prop-types';

const Employee = ({ image, name, title, phone, email }) => {
  const formatPhone = (phone) => {
    return phone.toString().replace(/(\d{3})(\d{2})(\d{3})/, '$1 $2 $3');
  };

  return (
    <div className={styles.employee}>
      {image && (
        <div className={styles.image}>
          <img src={image} alt={name} />
        </div>
      )}
      <div className={styles.bio}>
        <span className={styles.name}>{name}</span>
        {title && <span className={styles.meta}>{title}</span>}
        {phone && (
          <span className={styles.meta}>(+47) {formatPhone(phone)}</span>
        )}
        {email && (
          <span className={styles.meta}>
            <a href={`mailto:${email}`}>{email}</a>
          </span>
        )}
      </div>
    </div>
  );
};

Employee.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  phone: PropTypes.number,
  email: PropTypes.string,
};

Employee.defaultProps = {
  image: null,
  name: null,
  title: null,
  phone: null,
  email: null,
};

export default Employee;
