import styles from './Footer.module.scss';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.content}>
        <a href={`mailto:hei@smeh.no`} target="_blank" rel="noreferrer">
          hei@smeh.no
        </a>
        <a
          href={`https://www.google.com/maps/place/Inkognitogata+33A,+0256+Oslo/@59.9159536,10.7208229,17z/data=!3m1!4b1!4m6!3m5!1s0x46416dd5178dacff:0x504b18b99bf50ae!8m2!3d59.9159536!4d10.7208229!16s%2Fg%2F11cs79ycn0?entry=ttu`}
          target="_blank"
          rel="noreferrer"
        >
          Inkognitogata 33A, 0256 Oslo
        </a>
        <a
          href={`https://w2.brreg.no/enhet/sok/detalj.jsp?orgnr=931569120`}
          target="_blank"
          rel="noreferrer"
        >
          Org.nr.: 931 569 120
        </a>
      </div>
    </footer>
  );
};

export default Footer;
