import { Link } from 'react-router-dom';
import styles from './Header.module.scss';
import PropTypes from 'prop-types';
import Logo from '../Logo/Logo';

const Header = ({ logo }) => {
  return (
    <header className={styles.header}>
      <div>{logo && <Logo />}</div>
      <nav className={styles.nav}>
        <Link className={styles.link} to={`/om-oss`}>
          Om oss
        </Link>
        <Link className={styles.link} to={`/kontakt`}>
          Kontakt
        </Link>
      </nav>
    </header>
  );
};

Header.propTypes = {
  logo: PropTypes.bool,
};

Header.defaultProps = {
  logo: true,
};

export default Header;
