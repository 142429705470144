import React from 'react';
import styles from './Main.module.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Footer from '../Footer/Footer';
import Header from '../Header/Header';

const Main = ({ logo, children }) => {
  return (
    <main className={classNames(styles.main)}>
      <Header logo={logo} />
      <div className={styles.wrapper}>
        <div className={styles.content}>{children}</div>
      </div>
      <Footer />
    </main>
  );
};

Main.propTypes = {
  logo: PropTypes.bool,
  children: PropTypes.node,
};

Main.defaultProps = {
  logo: true,
  children: null,
};

export default Main;
