import styles from './Logo.module.scss';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import logo from '../../assets/logo.svg';

const Logo = ({ large, className }) => {
  return (
    <Link
      className={classNames(styles.logo, className, { [styles.large]: large })}
      to={'/'}
    >
      <img className={styles.svg} src={logo} alt="smeh" />
    </Link>
  );
};

Logo.propTypes = {
  large: PropTypes.bool,
  logo: PropTypes.string,
};

Logo.defaultProps = {
  large: false,
  logo: null,
};

export default Logo;
