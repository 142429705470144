/* eslint-disable no-case-declarations */

const defaultSettings = {
  version: 1,
};

export const reducer = (settings, action) => {
  switch (action.type) {
    case 'update_settings':
      const updatedSettings = {
        ...settings,
        [action.payload.key]: action.payload.value,
      };
      return updatedSettings;

    default:
      return settings;
  }
};

export const initialState = () => {
  return defaultSettings;
};
