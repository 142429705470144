import React from 'react';
import Main from '../components/Main/Main';
import Logo from '../components/Logo/Logo';

const Frontpage = () => {
  return (
    <Main logo={false}>
      <Logo large />
    </Main>
  );
};

export default Frontpage;
