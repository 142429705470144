import React from 'react';

import Main from '../components/Main/Main';
import Content from '../components/Content/Content';
import Heading from '../components/Heading/Heading';
import Text from '../components/Text/Text';

import group from '../assets/group.jpeg';

const About = () => {
  return (
    <Main>
      <Content image={group}>
        <Heading>Vi er Smeh. Et nytt reklamebyrå i Oslo. </Heading>
        <Text>
          Vi har startet opp fordi vi opplever at reklamebransjen trenger et
          byrå som oppnår like gode resultater på effekt som på kreativitet.
          Mange snakker om det - vi skal gjøre det. Her møter du små kjerneteam
          som jobber tett med kundene fra første møte til reklamen går på. Det
          reduserer interne prosesser i et byrå, men krever samtidig mer av alle
          som jobber i Smeh. Det krever også mer av kundene, der de blir som
          nære samarbeidspartnere. Men gjennom denne måten å jobbe på sikrer vi
          at det riktige budskapet blir løst på den smarteste måten. Det er sånn
          vi sammen kan lage kreativ reklame som faktisk funker. Vi i Smeh har
          gjort det før og skal fortsette med det.
        </Text>
        <Heading small>Kunder:</Heading>
        <Text>
          Hennig Olsen <span>/</span> Stine Sofies Stiftelse <span>/</span>{' '}
          Sætre <span>/</span>
          <br /> KPMG <span>/</span> Svg Property <span>/</span> Orkla{' '}
          <span>/</span> <br /> 24SevenOffice <span>/</span> BeSafe
        </Text>
      </Content>
    </Main>
  );
};

export default About;
