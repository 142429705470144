import styles from './Heading.module.scss';
import { H } from 'react-headings';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Heading = ({ className, small, children, ...props }) => {
  return (
    <H
      className={classNames(styles.heading, className, {
        [styles.small]: small,
      })}
      {...props}
    >
      {children}
    </H>
  );
};

Heading.propTypes = {
  className: PropTypes.string,
  small: PropTypes.bool,
};

Heading.defaultProps = {
  className: null,
  small: false,
};

export default Heading;
