import styles from './Grid.module.scss';
import PropTypes from 'prop-types';

const Grid = ({ children }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.grid}>{children}</div>
    </div>
  );
};

Grid.propTypes = {
  children: PropTypes.node,
};

Grid.defaultProps = {
  children: null,
};

export default Grid;
