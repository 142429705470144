import styles from './Text.module.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Text = ({ className, children }) => {
  return <p className={classNames(styles.text, className)}>{children}</p>;
};

Text.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

Text.defaultProps = {
  className: null,
  children: null,
};

export default Text;
