import styles from './Content.module.scss';
import PropTypes from 'prop-types';

const Content = ({ image, children }) => {
  return (
    <div className={styles.wrapper}>
      {image && <img src={image} alt={``} className={styles.image} />}
      <div className={styles.content}>{children}</div>
    </div>
  );
};

Content.propTypes = {
  image: PropTypes.string,
  children: PropTypes.node,
};

Content.defaultProps = {
  image: null,
  children: null,
};

export default Content;
